<template>
  <div class="container">
    <div class="mapContainer">
      <googlemaps-map
        ref="map"
        class="map"
        :center.sync="mapCenter"
        :zoom.sync="zoom"
        @click="onMapClick"
        @idle="onIdle"
        :options="{
          maxZoom: 15,
          styles: mapStyle,
          mapTypeControl: false,
          streetViewControl: false
        }"
      >
        <googlemaps-marker
          v-for="marker in mapMarkers"
          :ref="marker.id"
          :key="marker.id"
          :title="marker.title"
          :animation="marker.animation"
          :icon="marker.icon"
          :position="marker.position"
          @click="onMapMarkerClick(marker)"
        ></googlemaps-marker>
        <googlemaps-marker
          v-if="searching"
          key="center"
          :clickable="false"
          :icon="positionIcon"
          :position="searchPosition"
        ></googlemaps-marker>
      </googlemaps-map>
    </div>
  </div>
</template>
<script>
import mapStyle from "../assets/mapStyle";
import blackMarker from "../assets/marker-black.svg";
import orangeMarker from "../assets/marker-orange.svg";
import borderMarker from "../assets/marker-border.svg";

import positionIcon from "../assets/position.svg";

let infowindow = null;

export default {
  data() {
    return {
      bounds: null,
      mapMarkerIconSize: null,
      ignoreCenterOnSelectedStore: false,
      blackMarker,
      orangeMarker,
      borderMarker,
      positionIcon,
      mapStyle
    };
  },
  watch: {
    mapCenter: function() {
      setTimeout(() => {
        this.updateList();
      }, 50);
    },
    mapMarkers: function(markers) {
       if (!this.$refs.map) {
        setTimeout(() => {
          this.fitBounds(markers);
        }, 10);
      }else{
        this.fitBounds(markers);
      }
    },
    zoom: function() {
      setTimeout(() => {
        this.updateList();
      }, 50);
    }
  },
  computed: {
    config() {
      return this.$store.getters.config;
    },
    mapCenter: {
      get() {
        const pos = this.$store.getters.mapPosition;

        return { lat: Number(pos.lat), lng: Number(pos.lng) };
      },
      set(pos) {
        this.$store.dispatch("updateMapPosition", pos);

        return { lat: Number(pos.lat), lng: Number(pos.lng) };
      }
    },
    searchPosition: function() {
      return this.$store.getters.searchPosition;
    },

    zoom: {
      get() {
        return this.$store.getters.mapZoom;
      },
      set(zoom) {
        this.$store.dispatch("updateMapZoom", zoom);
        return zoom;
      }
    },
    searching() {
      return this.$store.getters.searching;
    },
    selectedStore: {
      get() {
        return this.$store.getters.selectedStore;
      },
      set(store) {
        this.$store.dispatch("updateSelectedStore", {
          store,
          updateMap: true
        });
      }
    },
    mapMarkers() {
      const results = this.$store.getters.results;
      let markers = [];

      results.map(item => {
        const marker = {};
        marker.id = item.id;
        marker.store = item;
        marker.title = item.name;
        marker.animation = 4;
        marker.optimized = false;
        marker.icon = {
          url: this.setIcon(marker),
          scaledSize: { height: 35, width: 30 },
        };
        marker.position = {
          lat: Number(item.lat),
          lng: Number(item.lng)
        };
        markers.push(marker);
      });
      if (!this.$refs.map) {
        setTimeout(() => {
          this.fitBounds(markers);
        }, 10);
      }
      return markers;
    }
  },
  methods: {
    setIcon(marker) {
      if (marker.store.premium) return orangeMarker;
      if (marker.store.premiumstore) return borderMarker;
      return blackMarker;
    },
    onIdle () {
        this.$store.dispatch("updateAutocomplete");
    },
    onMapClick() {
      if (infowindow) {
        infowindow.close();
      }
    },
    onMapMarkerClick(marker) {
      this.selectedStore = marker.store;

      const m = this.$refs[marker.id][0].$_marker;
      const map = this.$refs.map.$_map;

      if (infowindow) {
        infowindow.close();
      }

      infowindow = new window.google.maps.InfoWindow({
        content: this.getInfoWindowContent(marker)
      });

      infowindow.open(map, m);
    },
    updateList() {
      const bounds = this.$refs.map.getBounds();

      const stores = [];
      this.mapMarkers.map(marker => {
        if (
          (bounds && bounds.contains(marker.position)) ||
          this.premiumInRange(marker, 120)
        )
          stores.push(marker.store);
      });
      this.$store.dispatch("setListResults", stores);
    },
    premiumInRange(marker, distance) {
      return (
        marker.store.premiumstore &&
        this.distance(this.mapCenter, marker.position) <= distance
      );
    },
    distance(pos1, pos2) {
      var R = 6371.071; // Radius of the Earth in miles
      var rlat1 = pos1.lat * (Math.PI / 180); // Convert degrees to radians
      var rlat2 = pos2.lat * (Math.PI / 180); // Convert degrees to radians
      var difflat = rlat2 - rlat1; // Radian difference (latitudes)
      var difflon = (pos2.lng - pos1.lng) * (Math.PI / 180); // Radian difference (longitudes)

      var d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );
      return d;
    },
    getInfoWindowContent(marker){
      const {name, city, address, phone, email, website } = marker.store
      const {ids} = this.config
      const {markerInfo, markerInfoLink, phone:i18nPhone, email: i18nEmail, website:i18nWebsite} = this.config.i18n
      if(!ids) return "<strong>" +
          name +
          "</strong><br/>" +
          markerInfo +
          "&nbsp;<a href='#stores'>" +
          markerInfoLink +
          "</a>"
       return `<h2 class="h4">${name}</h2><p style='padding-bottom:20px'>${address}<br>${city}<br>${i18nPhone}: <a class="a" href="tel:${phone}">${phone}</a><br>${i18nEmail}: <a class="a" href="mailto:${email}">${email}</a></p><a href="http://${website}"  target="_blank" class="button" style="margin-bottom:0">${i18nWebsite}</a>`
    },
    fitBounds(markers) {
      const bounds = new window.google.maps.LatLngBounds();
      if (bounds) {
        markers.map(marker => bounds.extend(marker.position));
        // if(markers.length > 0 && this.config.site !== "fr") this.$refs.map.fitBounds(bounds);
      }
    }
  }
};
</script>
<style>
.mapContainer {
  height: 100%;
}
.map {
  flex: 100% 1 1;
  height: 450px;
}
</style>
