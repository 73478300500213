var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('section',{class:_vm.config.mapClasses},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[(_vm.config.sidebarVisible)?_c('div',{class:_vm.searchClasses},[_c('div',{staticClass:"map-search is-hidden-mobile"},[(_vm.config.countryVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.countries,"label":"name","value":_vm.selectedCountry,"clearable":false,"placeholder":_vm.config.i18n.selectcountry},on:{"input":function (country) {
                  if (country) { _vm.updateSelectedCountry(country); }
                }}}):_vm._e()],1),_c('div',{staticClass:"map-search"},[(_vm.config.cityVisible)?_c('input',{ref:"autocomplete",class:_vm.autocompleteInputStyle,attrs:{"placeholder":_vm.config.i18n.selectcity,"onfocus":"value = ''","type":"text","v-model":_vm.selectedCity}}):_vm._e()]),_c('div',{staticClass:"map-search is-hidden-mobile"},[(_vm.config.ovenVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.ovens,"label":"name","clearable":false,"placeholder":_vm.config.i18n.selectoven,"value":_vm.selectedOven},on:{"input":function (oven) {
                  _vm.updateSelectedOven(oven);
                }}}):_vm._e(),(_vm.config.storeVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.stores,"noDrop":_vm.showStoreDropdown,"label":"name","filter":_vm.nameSearch,"clearable":false,"value":_vm.selectedStore,"placeholder":_vm.config.i18n.selectstore},on:{"input":function (store) {
                  _vm.updateSelectedStore({ store: store, updateMap: true });
                }}}):_vm._e()],1),_c('Legend',{staticClass:"is-hidden-mobile"})],1):_vm._e(),_c('div',{class:_vm.searchMapClasses},[(!_vm.loading)?_c('Map'):_vm._e(),(_vm.searchClasses !='is-hidden')?[_c('div',{staticClass:"map-search__open is-hidden-desktop is-hidden-tablet "},[_c('a',{on:{"click":_vm.toggleSearch}},[_vm._v(_vm._s(_vm.config.i18n.openSearch))])]),(_vm.searchOpen)?[_c('div',{staticClass:"map-search"},[(_vm.config.countryVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.countries,"label":"name","value":_vm.selectedCountry,"clearable":false,"placeholder":_vm.config.i18n.selectcountry},on:{"input":function (country) {
                    if (country) { _vm.updateSelectedCountry(country); }
                  }}}):_vm._e()],1),_c('div',{staticClass:"map-search"},[(_vm.config.storeVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.stores,"noDrop":_vm.showStoreDropdown,"filter":_vm.nameSearch,"label":"name","clearable":false,"value":_vm.selectedStore,"placeholder":_vm.config.i18n.selectstore},on:{"input":function (store) {
                    _vm.updateSelectedStore({ store: store, updateMap: true });
                  }}}):_vm._e()],1),_c('div',{staticClass:"map-search"},[(_vm.config.ovenVisible)?_c('v-select',{class:_vm.autoCompleteStyle,attrs:{"options":_vm.ovens,"label":"name","clearable":false,"placeholder":_vm.config.i18n.selectoven,"value":_vm.selectedOven},on:{"input":function (oven) {
                    _vm.updateSelectedOven(oven);
                  }}}):_vm._e()],1),_c('Legend')]:_vm._e()]:_vm._e()],2)])])]),(_vm.config.listVisible)?_c('StoreList'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }