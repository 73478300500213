<template>
  <div id="app">
    <!--section class="section section--inverted loading" >
      <img src="@/assets/loading.svg" />
    </section-->
    <section :class="config.mapClasses" >
      <div class="container">
        <div class="columns">
          <div :class="searchClasses" v-if="config.sidebarVisible">
            <div class="map-search is-hidden-mobile">
              <v-select
                v-if="config.countryVisible"
                @input="
                  country => {
                    if (country) updateSelectedCountry(country);
                  }
                "
                :options="countries"
                label="name"
                :class="autoCompleteStyle"
                :value="selectedCountry"
                :clearable="false"
                :placeholder="config.i18n.selectcountry"
              ></v-select>
            </div>
            <div class="map-search">
              <input
                v-if="config.cityVisible"
                ref="autocomplete"
                :class="autocompleteInputStyle"
                :placeholder="config.i18n.selectcity"
                onfocus="value = ''"
                type="text"
                :v-model="selectedCity"
              />
            </div>
            <div class="map-search is-hidden-mobile">
              <v-select
                v-if="config.ovenVisible"
                @input="
                  oven => {
                    updateSelectedOven(oven);
                  }
                "
                :options="ovens"
                :class="autoCompleteStyle"
                label="name"
                :clearable="false"
                :placeholder="config.i18n.selectoven"
                :value="selectedOven"
              ></v-select>

              <v-select
                v-if="config.storeVisible"
                @input="
                  store => {
                    updateSelectedStore({ store, updateMap: true });
                  }
                "
                :options="stores"
                :noDrop="showStoreDropdown"
                :class="autoCompleteStyle"
                label="name"
                :filter="nameSearch"
                :clearable="false"
                :value="selectedStore"
                :placeholder="config.i18n.selectstore"
              ></v-select>
            </div>
            <Legend class="is-hidden-mobile" />
          </div>
          <div :class="searchMapClasses">
            <Map v-if="!loading" />
            <template v-if="searchClasses !='is-hidden'">
            <div class="map-search__open is-hidden-desktop is-hidden-tablet ">
              <a @click="toggleSearch" class="">{{ config.i18n.openSearch }}</a>
            </div>
            <template v-if="searchOpen">
              <div class="map-search">
                <v-select
                  v-if="config.countryVisible"
                  @input="
                    country => {
                      if (country) updateSelectedCountry(country);
                    }
                  "
                  :options="countries"
                  label="name"
                  :class="autoCompleteStyle"
                  :value="selectedCountry"
                  :clearable="false"
                  :placeholder="config.i18n.selectcountry"
                ></v-select>
              </div>
              <div class="map-search">
                <v-select
                  v-if="config.storeVisible"
                  @input="
                    store => {
                      updateSelectedStore({ store, updateMap: true });
                    }
                  "
                  :options="stores"
                  :noDrop="showStoreDropdown"
                  :filter="nameSearch"
                  :class="autoCompleteStyle"
                  label="name"
                  :clearable="false"
                  :value="selectedStore"
                  :placeholder="config.i18n.selectstore"
                ></v-select>
              </div>
              <div class="map-search">
                <v-select
                  v-if="config.ovenVisible"
                  @input="
                    oven => {
                      updateSelectedOven(oven);
                    }
                  "
                  :options="ovens"
                  :class="autoCompleteStyle"
                  label="name"
                  :clearable="false"
                  :placeholder="config.i18n.selectoven"
                  :value="selectedOven"
                ></v-select>
              </div>
              <Legend />
            </template>
            </template>
          </div>
        </div>
      </div>
    </section>
    <StoreList v-if="config.listVisible" />
  </div>
</template>

<script>
import Map from "@/components/Map";
import Legend from "@/components/Legend";
import StoreList from "@/components/StoreList";
import { mapActions } from "vuex";

export default {
  components: {
    Map,
    Legend,
    StoreList
  },
  data() {
    return {
      searchOpen: false,
      showStoreDropdown: true,
      country: this.selectedCountry || null
    };
  },
  computed: {
    store() {
      return this.$store.getters.selectedStore;
    },
    loading() {
      return this.$store.getters["loading"];
    },
    note() {
      const config = this.$store.getters.config;
      const oven = this.$store.getters.selectedOven;
      return config.i18n.note1.replace("[oven]", oven.name);
    },
    searchClasses(){
        const config = this.$store.getters.config;
        return config.selectedStore ? "is-hidden" : "column is-4"
    },
    searchMapClasses(){
        const config = this.$store.getters.config;
        return config.selectedStore || !config.sidebarVisible ? "column is-12" : "column is-8"
    },
    autocompleteInputStyle() {
      return this.$store.getters.config.mapClasses.includes("section--inverted")
        ? "input input--location hard search-location "
        : "input input--location input--bordered hard search-location";
    },
    autoCompleteStyle() {
      return this.$store.getters.config.mapClasses.includes("section--inverted")
        ? "suggest"
        : "suggest suggest--bordered";
    },
    selectedStore: {
      get() {
        return this.$store.getters.selectedStore;
      },
      set() {}
    },
    selectedCity: {
      get() {
        return this.$store.getters.selectedCity;
      },
      set() {}
    },
    selectedCountry: {
      get() {
        return this.$store.getters.selectedCountry;
      },
      set() {}
    },
    selectedOven: {
      get() {
        return this.$store.getters.selectedOven;
      },
      set() {}
    },
    countries() {
      return this.$store.getters.countries;
    },
    stores() {
      return this.$store.getters.results;
    },
    ovens() {
      return this.$store.getters.ovens;
    },
    config() {
      return this.$store.getters.config;
    }
  },
  mounted() {
     this.$nextTick(() => {
        this.$store.dispatch("initAutocomplete", this.$refs.autocomplete);
    })
  },
  methods: {
    nameSearch(options, search) {
      this.showStoreDropdown = search.length > 0 ? false : true;
      return options.filter(option =>
        option.name.toLowerCase().startsWith(search.toLowerCase())
      );
    },
    toggleSearch: function() {
      this.searchOpen = !this.searchOpen;
    },
    ...mapActions([
      "updateSelectedStore",
      "updateSelectedCountry",
      "updateSelectedOven"
    ])
  }
};
</script>


<style lang="scss">
html,
body,
#app {
}

.loading {
  text-align: center;
}

.loading img {
  width: 40px;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
