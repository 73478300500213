<template>
  <div class="map__legend">
    <a :href="config.legendLink">
      <img :src="mutedMarker" v-tooltip.top-center="config.i18n.legendClassic" />
      <p>
        {{ config.i18n.classic }}
      </p>
    </a>
    <a :href="config.legendLink" v-tooltip.top-center="config.i18n.legendPremium">
      <img :src="defaultMarker" />
      <p>
        {{ config.i18n.premium }}
      </p>
    </a>
    <a :href="config.legendLink" v-tooltip.top-center="config.i18n.legendPremiumStore">
      <img :src="borderedMarker" />
      <p>
        {{ config.i18n.premiumstore }}
      </p>
    </a>
  </div>
</template>
<script>
import defaultMarker from "@/assets/marker-orange.svg";
import mutedMarker from "@/assets/marker-black.svg";
import borderedMarker from "@/assets/marker-border.svg";

export default {
  props: ["id", "embedded"],
  data: () => ({
    defaultMarker,
    mutedMarker,
    borderedMarker
  }),
  computed: {
    config() {
      return this.$store.getters.config;
    }
  },
  methods: {}
};
</script>

<style></style>
