// sort by distance

const sortStores = (results, { lat, lng }) =>
  results.sort(function (a, b) {
    //  if (a.premiumstore > b.premiumstore) { return -1; }
    const dA = distance(a.lat, a.lng, lat, lng);
    const dB = distance(b.lat, b.lng, lat, lng);
    if (dA > dB) return 1;
    if (dA < dB) return -1;
    return 0;
  });

const prioStores = (results) => {
  const premiumstore = results.filter((result) => !!result.premiumstore) || [];
  const premium = results.filter((result) => !!result.premium) || [];
  const rest =
    results.filter((result) => !result.premium && !result.premiumstore) || [];
  return [...premiumstore, ...premium, ...rest];
};

const mapLoad = () =>
  new Promise((resolve) => {
    if (typeof google != "undefined") {
      resolve({});
    } else {
      // console.log("waiting for google maps.. ");
      // wait 2sec in case the map is still not loaded
      setTimeout(function () {
        if (typeof google != "undefined") {
          // console.log("google maps is loaded. ");
          resolve({});
          return;
        }
        // ok let's do one more round :P
        // console.log("waiting a bit more.. ");
        setTimeout(function () {
          // console.log("google maps is finally loaded.");
          resolve({});
        }, 5000);
      }, 2000);
    }
  });

const distance = (lat1, lon1, lat2, lon2) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
};

const COUNTRY_CENTER = {
  deat: { lat: 47.516231, lng: 14.550072 },
  en: { lat: 55.378051, lng: -3.435973 },
  it: { lat: 41.87194, lng: 12.56738 },
  fr: { lat: 46.227638, lng: 2.213749 },
  es: { lat: 40.463667, lng: -3.74922 },
  nl: { lat: 52.132633, lng: 5.291266 },
  itch: { lat: 46.818188, lng: 8.227512 },
  frch: { lat: 46.818188, lng: 8.227512 },
  dech: { lat: 46.818188, lng: 8.227512 },
  de: { lat: 51.165691, lng: 10.451526 },
  frbe: { lat: 50.7535324, lng: 4.644957 },
  nlbe: { lat: 52.2211233, lng: 5.309915 },
  sv: { lat: 59.334591, lng: 18.063240 }
};

export default {
  state: {
    selectedOven: null,
    selectedCountry: {
      slug: null,
    },
    isListShouldUpdate: false,
    selectedCity: "",
    selectedStore: null,
    autocomplete: null,
    autocompleteRef: "",
    searching: false,
    stores: [],
    ovens: [],
    categories: [],
    countries: [],
    results: [],
    listResults: [],
    selectedResult: {},
    config: {},
    loading: true,
    all: {},
    showPosition: false,
    searchPosition: {
      lat: 0,
      lng: 0,
    },
    mapPosition: {
      lat: 0,
      lng: 0,
    },
    mapZoom: 11,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    userLocation(state) {
      return state.userLocation;
    },
    selectedCity(state) {
      return state.selectedCity;
    },
    selectedStore(state) {
      return state.selectedStore;
    },
    selectedOven(state) {
      return state.selectedOven;
    },
    selectedCountry(state) {
      return state.selectedCountry;
    },
    results(state) {
      return state.results;
    },
    listResults(state) {
      return state.listResults;
    },
    ovens(state) {
      return state.ovens;
    },
    countries(state) {
      return state.countries;
    },
    categories(state) {
      return state.categories;
    },
    searching(state) {
      return state.searching;
    },
    getStoreById(state) {
      return (Id) => {
        return state.stores.find((item) => {
          return item.id === Id;
        });
      };
    },
    config(state) {
      return state.config;
    },
    mapPosition(state) {
      return state.mapPosition;
    },
    mapZoom(state) {
      return state.mapZoom;
    },
    searchPosition(state) {
      return state.searchPosition;
    },
  },
  mutations: {
    INIT_AUTOCOMPLETE(state, ref) {
      state.autocompleteRef = ref;
    },
    UPDATE_AUTOCOMPLETE(state, autocomplete) {
      state.autocomplete = autocomplete;
    },
    SET_ALL(state, all) {
      state.all = all;
    },
    SET_RESULTS(state, results) {
      state.results = results;
    },
    SET_LIST_RESULTS(state, results) {
      state.listResults = prioStores(
        sortStores(results, state.mapPosition)
      ).slice(0, 10);
    },
    SET_STORES(state, stores) {
      state.stores = stores;
    },
    SET_CATEGORIES(state,categories){
      state.categories = categories
    },
    SET_OVENS(state, ovens) {
      state.ovens = ovens;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_COUNTRY(state, country) {
      state.selectedCountry = country;
    },
    DEL_CITY(state) {
      state.selectedCity = "";
      if (state.autocompleteRef) state.autocompleteRef.value = "";
    },
    SET_OVEN(state, oven) {
      state.selectedOven = oven;
    },
    SET_STORE(state, store) {
      state.selectedStore = store;
      //state.isListShouldUpdate = true
       state.listResults = [store]
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
    SET_MAP_POSITION(state, position) {
      state.mapPosition = position;
    },
    SET_MAP_ZOOM(state, zoom) {
      state.mapZoom = zoom;
    },
    SET_SEARCHING(state, position) {
      state.searching = true;
      state.searchPosition = position;
      state.isListShouldUpdate = true;
    },
    RESET_SEARCHING(state) {
      state.searching = false;
    },
  },
  actions: {
    init({ commit, dispatch, state }) {
      const { api, site, selectedCountry, useLocation, ids } = state.config;
      const qCountry = state.selectedCountry.slug || selectedCountry;
      Promise.all([
        fetch(api + "/ovens.json?lang=" + site),
        fetch(api + "/countries.json?lang=" + site),
        fetch(api + "/categories.json?lang=" + site),
        fetch(
          api + "/stores.json?page=1&country=" + qCountry + "&lang=" + site + "&ids=" + ids
        ),
        mapLoad(),
      ])
        .then((result) =>
          Promise.all(result.map((v) => (v.json ? v.json() : v)))
        )
        .then((response) => {
          const [ovens, countries, categories, stores] = response;

          // init
          commit("SET_OVENS", ovens.data);
          commit("SET_CATEGORIES", categories.data);
          commit("SET_STORES", stores.data);
          commit("SET_COUNTRIES", countries.data);

          commit("SET_RESULTS", stores.data);
//          commit("SET_LIST_RESULTS", stores.data);

          // preselect configured country
          countries.data.map((country) => {
            if (qCountry === country.slug) commit("SET_COUNTRY", country);
          });

          // preset configured oven
          ovens.data.map((oven) => {
            if (state.config.selectedOven === oven.id)
              dispatch("updateSelectedOven", oven);
          });

          // preselect configured dealer
          stores.data.map((store) => {
            if (state.config.selectedStore === store.id) {
              commit("SET_STORES", [store]);
              commit("SET_RESULTS", [store]);
              commit("SET_LIST_RESULTS", [store]);
              dispatch("updateSelectedStore", { store, updateMap: true });
            }
          });

          // original
          commit("SET_ALL", {
            countries: countries.data,
            stores: stores.data,
            ovens: ovens.data,
          });

          if (state.autocompleteRef) this.dispatch("updateAutocomplete");

          // hide every store by default (latest requirement)

          const centerOfCountry = COUNTRY_CENTER[state.config.site]
          commit("SET_MAP_POSITION", centerOfCountry);
          commit("SET_MAP_ZOOM", 6);

          commit("SET_STORE", null);
          //commit("SET_RESULTS", stores.data);
          commit("SET_LIST_RESULTS", []);

          //console.log(centerOfCountry);

          // show them when geopos is enabled
          if ("geolocation" in navigator && useLocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const { longitude, latitude } = position.coords;
              commit("SET_MAP_POSITION", { lat: latitude, lng: longitude });
              commit("SET_SEARCHING", { lat: latitude, lng: longitude });
            });
          }
          
          commit("FINISH_LOADING");
        });
    },
    fetchStore({ commit }) {
      commit("SET_STORE");
    },
    initAutocomplete({ commit }, ref) {
      commit("INIT_AUTOCOMPLETE", ref);
    },
    updateMapPosition({ commit }, position) {
      commit("SET_MAP_POSITION", position);
    },
    updateMapZoom({ commit }, zoom) {
      commit("SET_MAP_ZOOM", zoom);
    },
    updateAutocomplete({ commit, state }) {
      const autocomplete = new google.maps.places.Autocomplete(
        state.autocompleteRef,
        {
          types: ["geocode"],
          componentRestrictions: { country: state.selectedCountry.slug },
        }
      );
      autocomplete.addListener("place_changed", () => {
        if (window.dataLayer)
          window.dataLayer.push({ event: "dealersearch-address" });
        let place = state.autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();

        commit("SET_RESULTS", state.stores);
        commit("SET_LIST_RESULTS", state.stores);
        commit("SET_STORE", null);
        commit("SET_MAP_POSITION", { lat, lng });
        commit("SET_MAP_ZOOM", 10);
        commit("SET_SEARCHING", { lat, lng });
      });

      commit("UPDATE_AUTOCOMPLETE", autocomplete);
    },
    setListResults({ commit, state }, stores) {
      if(state.isListShouldUpdate) commit("SET_LIST_RESULTS", stores);
    },
    updateSelectedOven({ commit }, oven) {
      commit("SET_OVEN", oven);
      commit("SET_STORE", null);
      commit("DEL_CITY");
    },
    updateSelectedCountry({ commit, dispatch }, country) {
      commit("SET_COUNTRY", country);
      commit("SET_STORE", null);
      commit("RESET_SEARCHING");

      dispatch("init");
      if (window.dataLayer)
        window.dataLayer.push({ event: "dealersearch-country" });
    },
    updateSelectedStore({ commit }, { store, updateMap }) {
      commit("SET_STORE", store);
      if (store && updateMap) {
        commit("SET_MAP_POSITION", { lat: store.lat, lng: store.lng });
        commit("SET_MAP_ZOOM", 10);
      }

      if (store && window.dataLayer)
        window.dataLayer.push({ event: "dealersearch-dealer" });
    },
  },
};
