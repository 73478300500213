import Vue from "vue";
import Vuex from "vuex";
import VueGoogleMaps from "vue-googlemaps";
import "vue-googlemaps/dist/vue-googlemaps.css";
import App from "./App";
import storeObj from "./store";
import vSelect from "vue-select";
import VTooltip from "v-tooltip";

/* eslint-disable no-new */

document.querySelectorAll(".vue-map").forEach((el) => {
	const id = el.dataset.id;
	const config =
		JSON.parse(document.getElementById("map-config-" + id).innerHTML) || {};

	storeObj.state = {
		...storeObj.state,
		config,
	};

	Vue.use(Vuex);
	const store = new Vuex.Store(storeObj);

	Vue.component("v-select", vSelect);

	Vue.use(VTooltip);

	Vue.use(VueGoogleMaps, {
		load: {
			apiKey: "AIzaSyDz2KJ824j-Egw_WvHo_XBecLs-_5-CgjY",
			libraries: ["places"],
			useBetaRenderer: false,
		},
	});

	Vue.config.productionTip = false;

	new Vue({
		el,
		store,
		components: { App },
		template: "<App/>",
		beforeCreate() {
			this.$store.dispatch("init");
		},
	});
});
