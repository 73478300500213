<template>
  <section :class="config.listClasses" id="stores">
    <div class="container">
      <div class="accordion">
        <div
          :class="
            selectedStore && selectedStore.id === store.id
              ? 'accordion-item accordion-item--status-active'
              : 'accordion-item'
          "
          v-for="store in listResults"
          :key="store.id"
        >
          <div
            class="accordion-item__header"
            @click="() => onSelectStore(store)"
          >
            <div class="accordion-item__title">
              <img
                :src="setIcon(store)"
                style="height:16px;  margin-right:5px;"
              />
              {{ store.name }}
            </div>
            <div class="accordion-item__open">
              <span class="chevron chevron--orientation-down"></span>
            </div>
          </div>
          <div class="accordion-item__body" style="padding-top: 0;">
            <Store
              v-if="selectedStore && selectedStore.id === store.id"
              :embedded="true"
            />
            <div
              class="accordion-item__close"
              @click="() => onCloseStore(store)"
            >
              <span class="chevron chevron--orientation-up"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Store from "./Store";
import { mapActions } from "vuex";
import premium from "../assets/premium.svg";

import blackMarker from "../assets/marker-black.svg";
import orangeMarker from "../assets/marker-orange.svg";
import borderMarker from "../assets/marker-border.svg";

export default {
  components: {
    Store
  },
  data: () => ({
    premium
  }),
  computed: {
    listResults() {
      return this.$store.getters.listResults;
    },
    selectedStore() {
      return this.$store.getters.selectedStore;
    },
    config() {
      return this.$store.getters.config;
    }
  },
  methods: {
    ...mapActions(["updateSelectedStore"]),
    onSelectStore(store) {
      this.updateSelectedStore({ store, updateMap: false });
    },
    onCloseStore() {
      this.updateSelectedStore({ store: null, updateMap: false });
    },
    setIcon(store) {
      if (store.premium) return orangeMarker;
      if (store.premiumstore) return borderMarker;
      return blackMarker;
    }
  }
};
</script>
